<template>
  <div class="page">
    <div class="page_body">
      <div class="top_bar tc">
        <img class="mt50 logo_img"  :src="img.app_icon">
        <p style="font-style:italic;color:#333;">淘街坊</p>
      </div>

      <div class="mt30 tl p020" style="color:#777;">
        <p>淘街坊致力于为你发现身边优惠</p>
        <p class="mt10">可通过以下方式联系我们：</p>
        <ol class="mt5">
          <li>电话：020-22043458</li>
          <li>网址：http://www.taojiefang.cn</li>
          <li>客服微信：a595373</li>
          <li>微信公众号：taojiefang10000</li>
        </ol>
      </div>

      <van-cell-group class="mt30 tl">
        <van-cell
          to="/html?leftLink=/about&url=http://app.taojiefang.cn/Article-detail?id=18112410141098182"
          title="查看《淘街坊用户使用许可服务协议》"
          is-link
        />
        <van-cell
          to="/html?leftLink=/about&url=http://app.taojiefang.cn/Article-detail?id=18112722325950891"
          title="查看《淘街坊隐私政策》"
          is-link
        />
      </van-cell-group>
    </div>

    <div class="copyright tc">&copy; 淘街坊 - 发现身边优惠</div>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import { CellGroup, Cell } from "vant";

Vue.use(CellGroup).use(Cell);

export default {
  name: "About",
  components: {
    StatusBar,
    NavBar
  },
  data() {
    return {
      img: {
        app_icon: require("@/assets/personal/t_logo-01.png")
      }
    };
  },
  mounted: function() {},
  methods: {}
};
</script>

<style scoped>
.page {
  background: #fff;
}
.top_bar {
  background: #fff;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}
.logo_img{
border-radius: 18px;
width: 100px;
height: 100px;
}
</style>
